var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('b-collapse',{staticClass:"card",attrs:{"open":false},scopedSlots:_vm._u([{key:"trigger",fn:function(props){return _c('div',{staticClass:"card-header",attrs:{"role":"button"}},[_c('p',{staticClass:"card-header-title"},[_vm._v(" "+_vm._s(_vm.rolesGroup.title)+" ")]),_c('a',{staticClass:"card-header-icon"},[_c('b-icon',{attrs:{"icon":props.open ? 'menu-down' : 'menu-up'}})],1)])}}])},[_c('div',{staticClass:"card-content is-paddingless"},[_c('b-table',{attrs:{"data":_vm.roles,"narrowed":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-table-column',{staticClass:"is-capitalized",attrs:{"field":"title","label":"Title"}},[_vm._v(" "+_vm._s(props.row.title)+" ")]),_c('b-table-column',{attrs:{"field":"has","label":"Has","width":"100"}},[_c('b-switch',{attrs:{"value":_vm.userRoleHas(props.row.id)},on:{"input":(value) =>
                  _vm.assignRole({
                    userId: _vm.user.id,
                    roleId: props.row.id,
                    has: value,
                  })}})],1),_c('b-table-column',{attrs:{"field":"delegate","label":"Delegate","width":"100"}},[_c('b-switch',{attrs:{"value":_vm.userRoleDelegate(props.row.id)},on:{"input":(value) =>
                  _vm.assignRole({
                    userId: _vm.user.id,
                    roleId: props.row.id,
                    delegate: value,
                  })}})],1)]}}])})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }