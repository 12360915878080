var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('b-modal',{attrs:{"active":true,"has-modal-card":""},on:{"close":_vm.closeModal}},[_c('div',{staticClass:"modal-card is-full"},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v(" "+_vm._s(_vm.isEditing ? 'Edit partner' : 'Create partner')+" ")])]),_c('section',{staticClass:"modal-card-body"},[(!_vm.isEditing)?_c('b-field',{attrs:{"label":"UID","message":_vm.errors.first('partnerUid'),"type":{ 'is-danger': _vm.errors.has('partnerUid') }}},[_c('b-input',{directives:[{name:"validate",rawName:"v-validate",value:({
              required: true,
              max: 8,
            }),expression:"{\n              required: true,\n              max: 8,\n            }"}],ref:"partnerUid",attrs:{"name":"partnerUid","data-vv-as":"partner UID","placeholder":"Partner UID","type":"text"},model:{value:(_vm.partnerUid),callback:function ($$v) {_vm.partnerUid=$$v},expression:"partnerUid"}})],1):_vm._e(),_c('b-field',{attrs:{"label":"URI","message":_vm.errors.first('uri'),"type":{ 'is-danger': _vm.errors.has('uri') }}},[_c('b-input',{directives:[{name:"validate",rawName:"v-validate",value:({
              required: true,
            }),expression:"{\n              required: true,\n            }"}],attrs:{"name":"uri","data-vv-as":"partner URI","placeholder":"Partner URI","type":"text"},model:{value:(_vm.uri),callback:function ($$v) {_vm.uri=$$v},expression:"uri"}})],1),_c('b-field',{attrs:{"label":"Token","message":_vm.errors.first('token'),"type":{ 'is-danger': _vm.errors.has('token') }}},[_c('template',{slot:"label"},[_c('span',{staticClass:"label"},[_vm._v("Token")]),_c('BCheckbox',{model:{value:(_vm.autoGenerateToken),callback:function ($$v) {_vm.autoGenerateToken=$$v},expression:"autoGenerateToken"}},[_vm._v(" Auto generate token ")])],1),_c('b-input',{directives:[{name:"show",rawName:"v-show",value:(!_vm.autoGenerateToken),expression:"!autoGenerateToken"},{name:"validate",rawName:"v-validate",value:({
              required: !_vm.autoGenerateToken,
            }),expression:"{\n              required: !autoGenerateToken,\n            }"}],attrs:{"name":"token","placeholder":"Bearer token","type":"text"},model:{value:(_vm.token),callback:function ($$v) {_vm.token=$$v},expression:"token"}})],2),_c('b-field',{attrs:{"grouped":""}},[_c('b-field',{attrs:{"label":"Active"}},[_c('b-checkbox',{model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}})],1),_c('b-field',{attrs:{"label":"Type","message":_vm.errors.first('type'),"type":{ 'is-danger': _vm.errors.has('type') }}},[_c('b-select',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true }),expression:"{ required: true }"}],attrs:{"name":"type","data-vv-as":"integration type"},model:{value:(_vm.integrationType),callback:function ($$v) {_vm.integrationType=$$v},expression:"integrationType"}},[_c('option',{attrs:{"value":"api"}},[_vm._v("api")]),_c('option',{attrs:{"value":"demo"}},[_vm._v("demo")])])],1)],1),_c('b-field',{attrs:{"label":"Description"}},[_c('b-input',{attrs:{"placeholder":"Some description","type":"textarea"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)],1),_c('footer',{staticClass:"modal-card-foot"},[_c('button',{staticClass:"button",attrs:{"type":"button"},on:{"click":_vm.closeModal}},[_vm._v(" Close ")]),_c('button',{staticClass:"button is-primary",attrs:{"type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.savePartner()}}},[_vm._v(" "+_vm._s(_vm.isEditing ? 'Save' : 'Create')+" ")])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }